export const getMeta = ({
  title,
  description,
  image = "https://media.zbooni.shop/assets/web/zbooni-og-img.png",
  siteUrl,
  additionalMeta = [],
}) => {
  return [
    { title },
    { name: "description", content: description },
    { property: "og:url", content: siteUrl },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:image", content: image },

    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:creator", content: "@zbooni_app" },
    { name: "twitter:site", content: "@zbooni_app" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { name: "twitter:image", content: image },
    ...additionalMeta,
  ];
};
